import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Slideshow from '../components/Slideshow';
import Carousel from '../components/Carousel';
import Midias from '../components/Midias';
import ProgramListForDay from '../components/ProgramaDoDia';

function Home() {
  return (
    <Box
      sx={{
        mt: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      {/* Slideshow Centralizado */}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Slideshow />
      </Box>

      {/* Rádio Player */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 4,
        }}
      >
        {/* Título "Curta nossa programação" */}
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: 'white',
            textAlign: 'center',
            fontFamily: '"Orbitron", sans-serif',
            letterSpacing: '0.0em',
            textTransform: 'uppercase',
            fontWeight: '700',
            mt: 10,
            mb: 2,
          }}
        >
          Ouvir a rádio
        </Typography>
        {/* Linha divisória */}
        <Box
          sx={{
            width: '20%',
            height: '2px',
            backgroundColor: '#F20519',
            margin: '10px auto',
          }}
        />
        {/* Descrição da equipe */}
        <Typography
          variant="subtitle1"
          sx={{
            color: 'rgb(186, 186, 186)',
            textAlign: 'center',
            fontFamily: '"Roboto", sans-serif',
            fontSize: '0.9rem',
            mb: 2,
          }}
        >
          Curta nossa programação ao vivo e sinta a energia da Sound Mix, com músicas que marcaram épocas e ainda fazem sucesso!
        </Typography>
        <Midias />
      </Box>

      {/* Conteúdo do meio */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(33, 33, 33, 0.8)',
          backgroundImage: 'url(https://ld-wp73.template-help.com/wordpress/prod_24537/v4/wp-content/uploads/2019/07/bg-contact.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          width: '100%',
          height: '100%',
          mb: 4,
          pb: 8,
          mt: 12,
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column', // Mudança para mobile
            width: '100%',
            maxWidth: '1420px',
            margin: '0 auto',
            px: 2, // Adiciona um padding horizontal
          }}
        >

          {/* Seção "Programas de Hoje" */}
          <Box
            sx={{
              mt: 15,
              mb: 0,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {/* Título "Programas de Hoje" */}
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: 'white',
                textAlign: 'center',
                fontFamily: '"Orbitron", sans-serif',
                letterSpacing: '0.0em',
                textTransform: 'uppercase',
                fontWeight: '700',
                mb: 2,
              }}
            >
              Hoje ao vivo
            </Typography>

            {/* Linha divisória */}
            <Box
              sx={{
                width: '50%',
                height: '2px',
                backgroundColor: '#F20519',
                margin: '10px auto',
              }}
            />

            {/* Descrição da programação */}
            <Typography
              variant="subtitle1"
              sx={{
                color: 'rgb(186, 186, 186)',
                textAlign: 'center',
                fontFamily: '"Roboto", sans-serif',
                fontSize: '0.9rem',
                mb: 3,
              }}
            >
              Acompanhe nossa programação ao vivo!
            </Typography>

            <ProgramListForDay />
          </Box>

          {/* Seção de Equipe Sound Mix e Carousel */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              mb: 4,
              pt:15
            }}
          >
            {/* Título "Equipe Sound Mix" */}
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: 'white',
                textAlign: 'center',
                fontFamily: '"Orbitron", sans-serif',
                letterSpacing: '0.0em',
                textTransform: 'uppercase',
                fontWeight: '700',
                mb: 2,
              }}
            >
              Equipe Sound Mix
            </Typography>

            {/* Linha divisória */}
            <Box
              sx={{
                width: '20%',
                height: '2px',
                backgroundColor: '#F20519',
                margin: '10px auto',
              }}
            />

            {/* Descrição da equipe */}
            <Typography
              variant="subtitle1"
              sx={{
                color: 'rgb(186, 186, 186)',
                textAlign: 'center',
                fontFamily: '"Roboto", sans-serif',
                fontSize: '0.9rem',
                mb: 5,
              }}
            >
              Conheça nossa equipe de DJs, a melhor programação musical ao vivo da internet é com eles!
            </Typography>

            <Carousel />

            {/* Nova Linha com Imagem e Frase */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column', // Alinha em coluna para mobile
                alignItems: 'center',
                width: '100%',
                mt: 8,
                px: 0,
                py:5
              }}
            >
              {/* Imagem */}
              <Box
                component="img"
                src="https://webradiosoundmix.com.br/api/wp-content/uploads/2024/09/mulher-sound-1.png"
                alt="Imagem Exemplo"
                sx={{
                  width: '100%',
                  maxWidth: '300px',
                  height: 'auto',
                  objectFit: 'cover',
                  borderRadius: 2,
                  mb: 2,
                }}
              />
              {/* Frase */}
              <Typography
                variant="body2"
                sx={{
                  color: 'white',
                  textAlign: 'center',
                  fontFamily: '"Roboto", sans-serif',
                  fontSize: '1rem',
                  px: 2,
                  mt:2
                }}
              >
                A Sound Mix é uma web rádio livre e independente, oferecendo uma seleção musical diversificada diretamente pela web. Nossa programação inclui desde as músicas mais contemporâneas até clássicos eternizados, que você não encontra nas rádios tradicionais. Destacamos também nossa programação ao vivo com nossos DJs.
              </Typography>
            </Box>
          </Box>


        </Container>
      </Box>
    </Box>
  );
}

export default Home;
