import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Home from './pages/Home';
import Chat from './pages/Chat';
import Header from './components/Header';
import Footer from './components/Footer';
import ParticlesBackground from './components/ParticlesBackground';
import TransitionWrapper from './components/TransitionWrapper'; // Importe o componente de transição



// Componente para envolver as rotas e aplicar a animação
const AppContent = () => {
  const location = useLocation();

  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      {/* Particles Background */}
      <ParticlesBackground />
      <Header />
      <Box component="main" sx={{ zIndex: 10, mt: 4 }}>
        <TransitionWrapper location={location}>
          <Routes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="/chat" element={<Chat />} />
          </Routes>
        </TransitionWrapper>
      </Box>
      <Box sx={{ width: '100%', textAlign: 'center', mt: 4, zIndex: 10 }}>
        <Footer />
      </Box>
    </Box>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;