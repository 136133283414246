import React, { useEffect, useState } from 'react';
import Multicarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Box, ImageListItemBar, Typography, IconButton } from '@mui/material';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// Configuração responsiva do carrossel
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    partialVisibilityGutter: 40,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 20,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 10,
  },
};

// Componente para a seta de navegação esquerda personalizada
const CustomLeftArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '50%',
      left: 0,
      zIndex: 10,
      color: 'white',
      backgroundColor: 'rgba(242, 5, 25, 0.5)', // Cor de fundo com transparência leve
      '&:hover': {
        backgroundColor: 'rgba(242, 5, 25, 0.8)', // Cor de fundo ao passar o mouse
      },
      transform: 'translateY(-50%)',
    }}
  >
    <ArrowBackIosIcon />
  </IconButton>
);

// Componente para a seta de navegação direita personalizada
const CustomRightArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '50%',
      right: 0,
      zIndex: 10,
      color: 'white',
      backgroundColor: 'rgba(242, 5, 25, 0.5)', // Cor de fundo com transparência leve
      '&:hover': {
        backgroundColor: 'rgba(242, 5, 25, 0.8)', // Cor de fundo ao passar o mouse
      },
      transform: 'translateY(-50%)',
    }}
  >
    <ArrowForwardIosIcon />
  </IconButton>
);

const Carousel = () => {
  const [djs, setDjs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch dos dados dos DJs
    axios
    .get('https://webradiosoundmix.com.br/api/wp-json/wp/v2/djs', {
      params: {
        per_page: 100, // Número de resultados desejado
      },
    })
    .then((response) => {
      setDjs(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Erro ao buscar os DJs:', error);
      setLoading(false);
    });
  }, []);

  // Função que renderiza os Skeletons enquanto os dados estão carregando
  const renderSkeletons = () => {
    return Array.from(new Array(4)).map((_, index) => (
      <Box key={index} sx={{ bgcolor: 'grey.900', width: '100%', maxWidth: 180, marginRight: 2 }}>
        <Skeleton variant="rectangular" width="100%" height={150} sx={{ bgcolor: 'grey.800' }} />
        <Box sx={{ p: 1 }}>
          <Skeleton width="60%" height={15} sx={{ bgcolor: 'grey.800' }} />
          <Skeleton width="40%" height={15} sx={{ bgcolor: 'grey.800' }} />
        </Box>
      </Box>
    ));
  };

  // Função que renderiza os DJs
  const renderDjs = () => {
    return djs.map((dj) => (
      <Box
        key={dj.id}
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: { xs: '200px', sm: '250px', md: '300px' }, // Tamanho adaptável
          height: { xs: '250px', sm: '300px' },
          overflow: 'hidden',
          border: '1px solid #333',
          borderRadius: '8px',
          boxShadow: 3,
          margin: '0 auto',
          backgroundColor: 'background.paper',
        }}
      >
        <img
          src={dj?.meta['foto-do-dj'] || 'url-da-imagem-padrao'}
          alt={`Foto do DJ ${dj?.meta['nome-do-dj']}`}
          loading="lazy"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
            borderRadius: '8px',
          }}
        />
        <ImageListItemBar
          title={
            <Typography
              variant="subtitle1"
              sx={{ fontSize: { xs: '20px', md: '22px' }, margin: 0, padding: 0, color: 'white', textAlign: 'center' }}
            >
              {dj?.meta['nome-do-dj']}
            </Typography>
          }
          subtitle={
            <Typography
              variant="subtitle2"
              sx={{ fontSize: '18px', margin: 0, padding: 0, color: 'yellow', textAlign: 'center' }}
            >
              {dj?.meta.cidade}
            </Typography>
          }
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2))',
            padding: '8px',
            textAlign: 'center',
            '& .MuiTypography-root': {
              lineHeight: 1,
            },
          }}
        />
      </Box>
    ));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: { xs: '20px', sm: '40px' },
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        borderRadius: 3,
        position: 'relative',
      }}
    >
      <Box sx={{ width: '100%', maxWidth: '1200px' }}>
        <Multicarousel
          centerMode={false}
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="transform 500ms ease-in-out"
          transitionDuration={300}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-10-px"
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {loading ? renderSkeletons() : renderDjs()}
        </Multicarousel>
      </Box>
    </Box>
  );
};

export default Carousel;
