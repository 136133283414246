import React, { useState, useEffect } from "react"; // Mantemos apenas uma importação de React
import Carousel from 'react-material-ui-carousel';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import { Box, Typography, Skeleton } from '@mui/material';
import { keyframes } from '@mui/system';

// Defina a animação fade-in
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

function TemplateSkeleton(props) {
    return (
<CardContent sx={{ 
    bgcolor: 'grey.900',
    height: { xs: 180, sm: '580px', md: '580px', lg: '580px', xl: '580px' }  // Alturas específicas para diferentes resoluções
    
    }}>
    <Skeleton
        variant="rectangular"
        width="1524px"  // Ajustado para 100% da largura do container
        height={380}  // Alturas específicas para diferentes resoluções
        sx={{ bgcolor: 'grey.800', animationColor: 'grey.600', marginTop: 0, marginBottom: 0 }}
    />
    <Box sx={{ pt: 0.9 }}>
        <Skeleton
            width="30%"
            height={'80px'}
            sx={{ marginTop: 0, marginBottom: 0, bgcolor: 'grey.800', animationColor: 'grey.600' }}
        />
        <Skeleton
            width="20%"
            height={'50px'}
            marginTop={0}
            marginBottom={0}
            sx={{ marginTop: 0, marginBottom: 0, bgcolor: 'grey.800', animationColor: 'grey.600' }}
        />
    </Box>
</CardContent>

    );
}

TemplateSkeleton.propTypes = {
    loading: PropTypes.bool,
};

export function YouTube() {
    return (
        <Box sx={{ overflow: 'hidden' }}>
            <TemplateSkeleton loading />
        </Box>
    );
}

// Componente Item para exibir imagem e informações
const Item = ({ item }) => (
    <ImageList
        sx={{
            width: '100%',
            height: { xs: 150, md: 523 }, // Altura ajustada para mobile (300px) e desktop (523px)
            overflow: 'hidden',
            borderRadius: '8px',
            backgroundColor: 'red',
        }}
    >
        <ImageListItem
            sx={{
                position: 'relative',
                borderRadius: '8px',
                overflow: 'hidden',
                width: { xs: 420, md: '1524px' }, // Ocupe 100% no mobile, 1524px no desktop
                height: '100%',
                maxWidth: '100vw', // Certifique-se de que o contêiner não exceda a largura da viewport
                ml: { xs: 0, md: 'auto' }, // Remove margens à esquerda no mobile
                mr: { xs: 0, md: 'auto' }, // Remove margens à direita no mobile
              
            }}
        >
            <img
                srcSet={``}
                src={item.imageUrl}
                alt={item.name}
                onError={(e) => (e.target.src = "default-image-url.jpg")}
                loading="lazy"
                style={{
                    width: '100%', // Ajuste de largura para mobile (100%)
                    height: '100%',
                    objectFit: 'cover',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width:'100%',
                    background: { xs: 'rgba(13, 13, 13, 0.4)', md: 'rgba(13, 13, 13, 0.7)' },
                    padding: { xs: '8px', md: '16px' }, // Menor padding no mobile
                    borderRadius: '8px 8px 0 0',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    color: 'white',
                }}
            >
                <Typography
                    variant={{xs: 'p', md: 'p' }} // Menor variante de texto para mobile
                    sx={{
                        color: 'white',
                        fontSize:{ xs: 20, md: 50 },
                        fontWeight: 'bold',
                        pl: { xs: 0, md: 0 }, // Menor padding à esquerda no mobile
                        textAlign: { xs: 'left', md: 'center' },
                        animation: 'inflate 0.6s ease-out',
                        transformOrigin: 'center center',
                        '@keyframes inflate': {
                            '0%': {
                                transform: 'scale(1.3)',
                                opacity: 0.8,
                            },
                            '50%': {
                                transform: 'scale(0.9)',
                                opacity: 1,
                            },
                            '100%': {
                                transform: 'scale(1)',
                                opacity: 1,
                            },
                        },
                    }}
                >
                    {item.name}
                </Typography>

                <Typography
                    variant='h6' // Ajuste do tamanho do texto para mobile
                    sx={{
                        color: 'yellow',
                        textAlign: { xs: 'left', md: 'center' },
                        animation: `${fadeIn} 1s ease-in`,
                        fontSize:{ xs: 15, md: 30 },
                    }}
                >
                    {item.description}
                </Typography>
            </Box>
        </ImageListItem>
    </ImageList>
);

// Componente para exibir o Carousel de banners
const Example = ({ banners }) => (
    <Box
        sx={{
            width: '100%',
            maxWidth: '1524px',
            height: 'auto',
            margin: '0 auto',
            padding: '0px', // Padding ajustado para remover espaços no mobile
            boxSizing: 'border-box',
            backgroundColor: '#0D0D0D',
        }}
    >
        <Carousel
            sx={{
                width: '100%',
                height: '100%',
            }}
            indicatorIconButtonProps={{
                style: {
                    color: '#F20519', // Cor dos indicadores
                }
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    color: '#ccc', // Cor do indicador ativo
                }
            }}
            navButtonsAlwaysVisible={true} // Mantém os indicadores sempre visíveis
        >
            {banners.map((banner) => (
                <Item
                    key={banner.id}
                    item={{
                        name: banner.programName,
                        description: banner.meta.dj,
                        imageUrl: banner.imageUrl
                    }}
                />
            ))}
        </Carousel>
    </Box>
);

const BannerList = () => {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBanners = async () => {
          try {
            const response = await fetch("https://webradiosoundmix.com.br/api/wp-json/wp/v2/banners?per_page=100"); // Ajuste para trazer todos os banners
            if (!response.ok) throw new Error(`Erro: ${response.status}`);
      
            const data = await response.json();
            const bannersWithDetails = await Promise.all(
              data.map(async (banner) => {
                let imageUrl = "default-image-url.jpg";
                const programName = banner.title?.rendered || "Nome do Programa não disponível";
                let djName = "Nome do DJ não disponível";
      
                // Fetch da imagem do banner
                if (banner._links?.["wp:attachment"]) {
                  const attachmentUrl = banner._links["wp:attachment"][0].href;
                  try {
                    const attachmentResponse = await fetch(attachmentUrl);
                    if (attachmentResponse.ok) {
                      const attachmentData = await attachmentResponse.json();
                      const image = attachmentData.find((item) => item.media_type === "image");
                      imageUrl = image ? image.media_details.sizes.full.source_url : imageUrl;
                    }
                  } catch (err) {
                    console.error("Erro ao buscar a imagem do banner:", err);
                  }
                }
      
                // Fetch do DJ associado
                if (banner.acf?.dj?.length) {
                  const djId = banner.acf.dj[0];
                  try {
                    const djResponse = await fetch(`https://webradiosoundmix.com.br/api/wp-json/wp/v2/djs/${djId}?per_page=100`); // Ajuste para buscar todos os DJs
                    if (djResponse.ok) {
                      const djData = await djResponse.json();
                      djName = djData.title?.rendered || "Nome do DJ não disponível";
                    }
                  } catch (err) {
                    console.error("Erro ao buscar o nome do DJ:", err);
                  }
                }
      
                return { ...banner, imageUrl, programName, djName };
              })
            );
      
            setBanners(bannersWithDetails);
          } catch (error) {
            console.error("Erro ao buscar os banners:", error);
            setError(error.message);
          } finally {
            setLoading(false);
          }
        };
      
        fetchBanners();
      }, []);
      

    if (loading) return <YouTube />;
    if (error) return <div>Erro: {error}</div>;

    return <Example banners={banners} />;
};

export default BannerList;
