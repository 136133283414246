import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { CssBaseline, GlobalStyles } from '@mui/material';
import './global.css';


// https://demo.templatemonster.com/pt-br/demo/297700.html?_gl=1*yv8ogf*_gcl_aw*R0NMLjE3MjYwMjM3NzguQ2p3S0NBanczUC0yQmhBRUVpd0EzeVBod0lsYmIwSV9FcU0zM1V6N1lLWktqOGJJWkhnY1NkUExBUFZ5SVltQkZwXzRoSEVmNFdjUFZ4b0NjcHNRQXZEX0J3RQ..*_gcl_au*MjEwMDIwNjE1OS4xNzI2MDIzNzQw*_ga*MTc3NjgzMjAxNi4xNzI2MDIzNzM1*_ga_FTPYEGT5LY*MTcyNjAyMzczNS4xLjEuMTcyNjAyNDIwNi4xNi4wLjA.
const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        backgroundColor: '#121215',
        color: '#fff', // Isso é opcional, caso queira que o texto seja branco por padrão
        margin: 0,
        padding: 0,
        minHeight: '100vh', // Garante que cubra toda a tela
      },
      '#root': {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }
    }}
  />
);

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    {globalStyles}
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
