import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, Avatar, CircularProgress } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

// Importar e configurar plugins do dayjs
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function Chat() {
  const [tabIndex, setTabIndex] = useState(0);
  const [programas, setProgramas] = useState([]);
  const [djs, setDjs] = useState([]);
  const [loadingProgramas, setLoadingProgramas] = useState(true);
  const [loadingDjs, setLoadingDjs] = useState(true);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const getCurrentDay = () => {
    const day = dayjs().locale('pt-br').format('dddd');
    return day.charAt(0).toUpperCase() + day.slice(1);
  };

  // Função para verificar se o programa está no ar com base no horário
  const isOnAir = (startTime, endTime) => {
    const now = dayjs();

    const start = dayjs(`${dayjs().format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm', true);
    const end = dayjs(`${dayjs().format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm', true);

    if (end.isBefore(start)) {
      end.add(1, 'day');
    }

    return now.isAfter(start) && now.isBefore(end);
  };

  useEffect(() => {
    const fetchProgramas = async () => {
      try {
        const res = await axios.get('https://webradiosoundmix.com.br/api/wp-json/wp/v2/programacao?per_page=100'); // Ajuste para buscar todos os programas
        setProgramas(res.data);
      } catch (error) {
        console.error('Erro ao buscar programas:', error);
      } finally {
        setLoadingProgramas(false);
      }
    };
  
    const fetchDjs = async () => {
      try {
        const res = await axios.get('https://webradiosoundmix.com.br/api/wp-json/wp/v2/djs?per_page=100'); // Ajuste para buscar todos os DJs
        setDjs(res.data);
      } catch (error) {
        console.error('Erro ao buscar DJs:', error);
      } finally {
        setLoadingDjs(false);
      }
    };
  
    fetchProgramas();
    fetchDjs();
  }, []);
  

  useEffect(() => {
    const interval = setInterval(() => {
      setProgramas(prevProgramas => [...prevProgramas]); // Força a atualização do estado
    }, 60000); // Atualiza a cada minuto

    return () => clearInterval(interval);
  }, []);

  const programasDoDia = programas.filter(programa => programa.meta.dia === getCurrentDay());

  const getDjDetails = (djName) => {
    return djs.find(dj => dj.meta['nome-do-dj'] === djName) || {};
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        mt: 4,
        mx: 'auto',
        p: 2,
        width: '100%',
        maxWidth: '100%',
        backgroundColor: '#222',
        color: '#fff',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          flexBasis: { xs: '100%', sm: '70%' },
          backgroundColor: '#000',
          p: 1,
          mr: { sm: 1 },
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: { xs: '670px', sm: '700px' },
          }}
        >
          <iframe
            src="https://minnit.chat/c/Riodejaneiro1000grausNaNet?embed&amp;&amp;nickname="
            style={{ border: 'none', width: '100%', height: '100%' }}
            allowTransparency="true"
            title="Chat"
          />
        </Box>
      </Box>

      <Box
        sx={{
          flexBasis: { xs: '100%', sm: '35%' },
          backgroundColor: '#333',
          p: 2,
          mt: { xs: 2, sm: 0 },
          borderRadius: 2,
          boxShadow: 3,
          color: '#fff',
        }}
      >
        <iframe 
          src="https://webradiosoundmix.com.br/player.html" 
          width="100%" 
          height="400" 
          style={{ border: 'none' }} 
          title="Player de Áudio"
        />

        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          textColor="inherit"
          indicatorColor="white"
          sx={{
            '& .MuiTab-root': {
              fontSize: '1rem',
              fontWeight: 'bold',
              letterSpacing: '0.05rem',
              textTransform: 'uppercase',
              color: '#f1f1f1',
              '&.Mui-selected': {
                color: '#bafcba',
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: 'green',
            },
          }}
        >
          <Tab label="Programação do Dia" />
          <Tab label="Player de Vídeo" />
        </Tabs>

        {tabIndex === 0 && (
          <Box sx={{ mt: 2 }}>
            {loadingProgramas || loadingDjs ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <CircularProgress color="inherit" />
              </Box>
            ) : programasDoDia.length > 0 ? (
              programasDoDia.map((programa, index) => {
                const { meta } = programa;
                const djDetails = getDjDetails(meta.dj);
                const onAir = isOnAir(meta['hora-inicio'], meta['hora-fim']);

                return (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 0,
                      mb: 1,
                      backgroundColor: onAir ? '#2e7d32' : '#444',
                      borderRadius: 2,
                      position: 'relative',
                    }}
                  >
                    <Avatar
                      src={djDetails?.meta?.['foto-do-dj']}
                      alt={meta.dj}
                      sx={{ width: 56, height: 56, mr: 1, ml:1 }}
                    />
                    <Box sx={{ flex: 1 }}>
                      <Typography sx={{color:'#e2cd56', fontFamily: '"Orbitron", sans-serif',fontWeight:'700'}} variant="p">{meta['nome-do-programa']}</Typography>
                      <Typography sx={{fontWeight:'900'}} variant="body2">{`${meta['hora-inicio']} - ${meta['hora-fim']}`}</Typography>
                      <Typography variant="body2">{`${meta.dj} - ${djDetails?.meta?.cidade || ''}`}</Typography>
                    </Box>
                    {onAir && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          backgroundColor: '#f44336',
                          color: '#fff',
                          padding: '4px 8px',
                          borderRadius: '0 8px 0 8px',
                          animation: 'pulse 1.5s infinite',
                        }}
                      >
                        NO AR
                      </Box>
                    )}
                  </Box>
                );
              })
            ) : (
              <Typography variant="body1">Nenhuma programação ao vivo para hoje.</Typography>
            )}
          </Box>
        )}

        {tabIndex === 1 && (
          <Box sx={{ mt: 2 }}>
            <iframe
              src="https://playerv.hospedagemvps.com.br/video/soundmix/1/true/true/YzNSdGRqRXVjM0oyYVdZdVkyOXQrWg"
              width="100%"
              height="315"
              style={{ border: 'none' }}
              title="Player de Vídeo"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

// Estilos globais
const styles = `
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

const styleElement = document.createElement("style");
styleElement.textContent = styles;
document.head.appendChild(styleElement);

export default Chat;
