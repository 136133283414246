// TransitionWrapper.js
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './TransitionStyles.css'; // Importe o CSS com os estilos de transição

const TransitionWrapper = ({ children, location }) => (
  <TransitionGroup>
    <CSSTransition
      key={location.pathname}
      timeout={300}
      classNames="fade"
    >
      <div>{children}</div>
    </CSSTransition>
  </TransitionGroup>
);

export default TransitionWrapper;
