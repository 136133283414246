import React from 'react';
import { Box } from '@mui/material';

function Midias() {
  return (
    <Box sx={{ mt: 0, textAlign: 'center' }}>
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' }, // Coluna em mobile, linha em desktop
          justifyContent: 'center', 
          gap: { xs: 1, sm: 4 }, // Espaçamento adaptável
          alignItems: 'center', // Centraliza os itens verticalmente
        }}
      >
        {/* Player de Áudio */}
        <Box
          sx={{
            width: { xs: '450px', sm: '500px' }, // 100% em mobile, 45% em desktop
            maxWidth: '500px', // Limita a largura máxima
            height: { xs: '430px', sm: '400px' }, // Ajusta a altura em desktop
            borderRadius: 2,
            mb: { xs: 2, sm: 0 }, // Margem inferior em mobile
            overflow: 'hidden', // Evita barras de rolagem
            display: 'flex',
            justifyContent: 'center', // Centraliza o iframe horizontalmente
            alignItems: 'center', // Centraliza o iframe verticalmente
          }}
        >
          <iframe
            src="https://webradiosoundmix.com.br/player.html"
            width="100%" // Ajusta a largura ao tamanho do container
            height="100%" // Ajusta a altura ao tamanho do container
            style={{ border: 'none' }} // Remove a borda do iframe
            title="Web Rádio Player"
          />
        </Box>

        {/* Player de Vídeo */}
        <Box
          sx={{
            width: { xs: '400px', sm: '500px' }, // 100% em mobile, 45% em desktop
            maxWidth: '500px', // Limita a largura máxima
            height: { xs: '430px', sm: '400px'  }, // Ajusta a altura em desktop
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'center', // Centraliza o iframe horizontalmente
            alignItems: 'center', // Centraliza o iframe verticalmente
            
          }}
        >
          <iframe 
            src="https://playerv.hospedagemvps.com.br/video/soundmix/1/true/true/YzNSdGRqRXVjM0oyYVdZdVkyOXQrWg" 
            name="video" 
            scrolling="no" 
            width="100%" // Ajusta a largura ao tamanho do container
            height="100%" // Ajusta a altura ao tamanho do container
            style={{ border: 'none' }} 
            title="Player de Vídeo"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Midias;
