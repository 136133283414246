import React, { useState, useEffect, useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ProgramacaoModal from './ProgramacaoModal';  // Importe o modal de programação

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger({
    threshold: 0,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Header() {
  const [showAppBar, setShowAppBar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);  // Estado para controlar o modal de programação

  

  const handleScroll = useCallback(() => {
    if (window.scrollY > lastScrollY) {
      setShowAppBar(false);
    } else {
      setShowAppBar(true);
    }
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <HideOnScroll>
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            height: { xs: '64px', md: 'auto' },
            paddingY: { xs: 1, md: 1 },
            transition: 'transform 0.3s ease',
            transform: showAppBar ? 'translateY(0)' : 'translateY(-100%)',
          }}
        >
          <Container maxWidth={false} sx={{ maxWidth: '1200px', margin: '0 auto' }}>
            <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              
              {/* Logo para telas menores */}
              <Box sx={{ display: { xs: 'flex', md: 'none' }, flex: 1, alignItems: 'center' }}>
                <img
                  src="https://webradiosoundmix.com.br/api/wp-content/uploads/2024/09/cropped-logo.png"
                  alt="Logo sound mix"
                  style={{ width: '80px', height: 'auto' }}
                />
              </Box>

              {/* Ícone do hambúrguer para telas menores */}
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
                sx={{ display: { xs: 'flex', md: 'none' }, fontSize: '2.5rem' }} // Aumentar o tamanho do ícone do hambúrguer
              >
                <MenuIcon />
              </IconButton>

              {/* Logo e menu de navegação para desktop */}
              <Box sx={{ display: { xs: 'none', md: 'flex' }, flex: 1, alignItems: 'center' }}>
                <img
                  src="https://webradiosoundmix.com.br/api/wp-content/uploads/2024/09/cropped-logo.png"
                  alt="Logo sound mix"
                  style={{ width: '100px', height: 'auto' }}
                />
                <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    color="inherit"
                    component={Link}
                    to="/"
                    sx={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      mx: 1,
                      transition: 'transform 0.3s ease, background-color 0.3s ease, color 0.3s ease',
                      backgroundColor: 'transparent',
                      color: 'inherit',
                      '&:hover': {
                        transform: 'scale(1.2)',
                        backgroundColor: 'white',
                        color: 'red',
                      },
                    }}
                  >
                    Home
                  </Button>
                  <Button
                    color="inherit"
                    component={Link}
                    to="/chat"
                    sx={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      mx: 1,
                      transition: 'transform 0.3s ease, background-color 0.3s ease, color 0.3s ease',
                      backgroundColor: 'transparent',
                      color: 'inherit',
                      '&:hover': {
                        transform: 'scale(1.2)',
                        backgroundColor: 'white',
                        color: 'red',
                      },
                    }}
                  >
                    Chat
                  </Button>
                  <Button
                    color="inherit"
                    onClick={() => setOpenModal(true)} // Abre o modal ao clicar
                    sx={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      mx: 1,
                      transition: 'transform 0.3s ease, background-color 0.3s ease, color 0.3s ease',
                      backgroundColor: 'transparent',
                      color: 'inherit',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        backgroundColor: 'white',
                        color: 'red',
                      },
                    }}
                  >
                    Programação
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', ml: 'auto', alignItems: 'center' }}>
                  <Tooltip title="Siga nossa página no Instagram">
                    <IconButton
                      href="https://www.instagram.com"
                      target="_blank"
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        padding: 1,
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                        fontSize: '1.5rem',
                        mx: 2, // Adicionado espaçamento entre os ícones
                      }}
                    >
                      <InstagramIcon sx={{ color: '#E1306C' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Nosso Grupo no Facebook">
                    <IconButton
                      href="https://www.facebook.com/groups/865472513573588"
                      target="_blank"
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        padding: 1,
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                        fontSize: '1.5rem',
                        mx: 2, // Adicionado espaçamento entre os ícones
                      }}
                    >
                      <FacebookIcon sx={{ color: '#4267B2' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Nosso Canal no YouTube">
                    <IconButton
                      href="http://www.youtube.com/@webradiosoundmix8776"
                      target="_blank"
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        padding: 1,
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                        fontSize: '1.5rem',
                        mx: 2, // Adicionado espaçamento entre os ícones
                      }}
                    >
                      <YouTubeIcon sx={{ color: '#FF0000' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Toolbar>
          </Container>

          {/* Drawer para o menu suspenso no mobile */}
          <Drawer
            anchor="left"
            open={openDrawer}
            onClose={toggleDrawer(false)}
            sx={{
              '.MuiDrawer-paper': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fundo preto
                width: 250,
                height: '100%', // Para ocupar toda a altura da tela
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
              },
              '.MuiBackdrop-root': {
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Escurecimento da área abaixo do menu
              },
            }}
          >
            <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, alignItems: 'center', p: 2 }}>
                <Tooltip title="Siga nossa página no Instagram">
                  <IconButton
                    href="https://www.instagram.com"
                    target="_blank"
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: 1,
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                      fontSize: '1.5rem',
                      mb: 1, // Margem inferior
                    }}
                  >
                    <InstagramIcon sx={{ color: '#E1306C' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Nosso Grupo no Facebook">
                  <IconButton
                    href="https://www.facebook.com/groups/865472513573588"
                    target="_blank"
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: 1,
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                      fontSize: '1.5rem',
                      mb: 1, // Margem inferior
                    }}
                  >
                    <FacebookIcon sx={{ color: '#4267B2' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Nosso Canal no YouTube">
                  <IconButton
                    href="http://www.youtube.com/@webradiosoundmix8776"
                    target="_blank"
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: 1,
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                      fontSize: '1.5rem',
                      mb: 1, // Margem inferior
                    }}
                  >
                    <YouTubeIcon sx={{ color: '#FF0000' }} />
                  </IconButton>
                </Tooltip>
              </Box>
               <Divider sx={{ borderColor: 'white' }} />
              <List>
                <ListItem button component={Link} to="/">
                  <ListItemText primary="Home" primaryTypographyProps={{ fontSize: '1.5rem', color: 'white' }} />
                </ListItem>
                <ListItem button component={Link} to="/chat">
                  <ListItemText primary="Chat" primaryTypographyProps={{ fontSize: '1.5rem', color: 'white' }} />
                </ListItem>
                <ListItem button onClick={() => setOpenModal(true)}>
                  <ListItemText primary="Programação" primaryTypographyProps={{ fontSize: '1.5rem', color: 'white' }} />
                </ListItem>
              </List>
             

            </Box>
          </Drawer>
        </AppBar>
      </HideOnScroll>

      {/* Renderização do modal de programação */}
      <ProgramacaoModal open={openModal} handleClose={handleCloseModal} />
    </Box>
  );
}

export default Header;
