import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box sx={{ mt: 10, py: 5,  borderTop: '10px solid #b70727', backgroundColor: '#0D0D0D', color: '#fff', textAlign: 'center' }}>
      <Typography variant="body2">
        © 2024 Web Rádio Soundmix
      </Typography>
    </Box>
  );
}

export default Footer;