import React, { useEffect, useState } from 'react';
import { Container, Typography, List, ListItem, ListItemAvatar, Avatar, Divider, ListItemText, Box, Skeleton } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'; // Importa o idioma em português para o Day.js
import { keyframes } from '@mui/system';

// Define a animação de pulsação com brilho para elementos que precisam destacar ao vivo
const pulseGlowAnimation = keyframes`
  0% {
    opacity: 0.5;
    text-shadow: 0 0 10px rgba(255, 255, 0, 0.5), 0 0 20px rgba(255, 255, 0, 0.3);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 20px rgba(255, 255, 0, 0.8), 0 0 30px rgba(255, 255, 0, 0.6);
  }
  100% {
    opacity: 0.5;
    text-shadow: 0 0 10px rgba(255, 255, 0, 0.5), 0 0 20px rgba(255, 255, 0, 0.3);
  }
`;

// Função que retorna o nome do dia atual em português, formatando a primeira letra em maiúscula
const getTodayInPortuguese = () => {
  dayjs.locale('pt-br');
  const today = dayjs().format('dddd');
  return today.charAt(0).toUpperCase() + today.slice(1);
};

// Função para converter a string de horário em um objeto Day.js
const parseTime = (time) => {
  const [hours, minutes] = time.split(':');
  if (hours && minutes && !isNaN(hours) && !isNaN(minutes)) {
    const parsedTime = dayjs().hour(hours).minute(minutes).second(0).millisecond(0);
    if (parsedTime.isValid()) {
      return parsedTime;
    }
  }
  console.error(`Hora inválida: ${time}`);
  return dayjs(); // Retorna a hora atual caso o horário seja inválido
};

// Verifica se o programa está ao vivo comparando o horário atual com o horário de início e fim do programa
const isProgramLive = (program) => {
  const startTime = parseTime(program.meta['hora-inicio']);
  const endTime = parseTime(program.meta['hora-fim']);
  const now = dayjs();
  return startTime.isBefore(now) && endTime.isAfter(now);
};

// Componente principal que lista os programas
const ProgramList = () => {
  const [programs, setPrograms] = useState([]); // Armazena os programas
  const [loading, setLoading] = useState(true); // Indica se os dados estão sendo carregados

// Função para buscar os dados dos programas e DJs da API
const fetchProgramsAndDjs = async () => {
  try {
    const [programsResponse, djsResponse] = await Promise.all([
      axios.get('https://webradiosoundmix.com.br/api/wp-json/wp/v2/programacao?per_page=100'), // Endpoint da programação com todos os dados
      axios.get('https://webradiosoundmix.com.br/api/wp-json/wp/v2/djs?per_page=100') // Endpoint dos DJs com todos os dados
    ]);

    // Filtra os programas para o dia atual
    const today = getTodayInPortuguese();
    const filteredPrograms = programsResponse.data.filter(program => program.meta.dia === today);

    // Cria um mapa de DJs, associando seus nomes normalizados às fotos
    const djMap = djsResponse.data.reduce((map, dj) => {
      const normalizedDjName = dj.title.rendered.trim().toLowerCase();
      map[normalizedDjName] = dj.meta['foto-do-dj'];
      return map;
    }, {});

    // Associa a foto do DJ ao programa correspondente
    const updatedPrograms = filteredPrograms.map(program => {
      const djNameNormalized = program.meta['dj'].trim().toLowerCase();
      return {
        ...program,
        djPhoto: djMap[djNameNormalized] || 'https://img.radios.com.br/radio/lg/radio38453_1469453524.jpg' // Foto padrão
      };
    });

    // Ordena os programas pelo horário de início
    const sortedPrograms = updatedPrograms.sort((a, b) => {
      const startTimeA = parseTime(a.meta['hora-inicio']);
      const startTimeB = parseTime(b.meta['hora-inicio']);
      return startTimeA.isAfter(startTimeB) ? 1 : -1;
    });

    setPrograms(sortedPrograms); // Atualiza o estado com os programas ordenados
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    setLoading(false); // Indica que o carregamento terminou
  }
};


  // Carrega os dados ao montar o componente e define um intervalo para atualizações periódicas
  useEffect(() => {
    fetchProgramsAndDjs();

    // Atualiza os programas a cada 1 minuto (60000 ms)
    const intervalId = setInterval(() => {
      fetchProgramsAndDjs();
    }, 60000);

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
  }, []);

  // Define a cor de destaque do fundo dependendo se o programa está ao vivo ou não
  const getHighlightColor = (index, isLive) => {
    if (isLive) return '#083408'; // Verde para programas ao vivo
    return index % 2 === 0 ? 'rgb(51, 47, 71, 0.4)' : 'rgb(51, 47, 71, 0.8)'; // Alterna as cores de fundo para programas não ao vivo
  };

  // Texto de destaque para programas ao vivo
  const getHighlightText = (isLive) => {
    return isLive ? 'NO AR' : ''; // Exibe "NO AR" se o programa estiver ao vivo
  };

  // Exibe o estado de carregamento com skeletons (placeholders)
  if (loading) {
    return (
      <Container
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          padding: 2,
          borderRadius: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          <List sx={{ width: '100%', maxWidth: 800 }}>
            {[...Array(4)].map((_, index) => (
              <React.Fragment key={index}>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    bgcolor: index % 2 === 0 ? '#444' : '#333', // Alterna a cor do fundo
                    color: '#ccc'
                  }}
                >
                  <ListItemAvatar>
                    <Skeleton
                      variant="circular"
                      width={40}
                      height={40}
                      sx={{
                        bgcolor: 'rgba(255, 255, 255, 0.2)',
                        animation: `${pulseGlowAnimation} 1.5s ease-in-out infinite` // Animação de brilho no carregamento
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Skeleton
                        width="60%"
                        sx={{
                          bgcolor: 'rgba(255, 255, 255, 0.2)',
                          animation: `${pulseGlowAnimation} 1.5s ease-in-out infinite`
                        }}
                      />
                    }
                    secondary={
                      <Skeleton
                        width="40%"
                        sx={{
                          bgcolor: 'rgba(255, 255, 255, 0.2)',
                          animation: `${pulseGlowAnimation} 1.5s ease-in-out infinite`
                        }}
                      />
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Container>
    );
  }

  // Exibe a lista de programas
  return (
    <Container
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        padding: {
          xs: 1,  // para dispositivos móveis
          md: '20px 0 20px 0' // para desktop
        },
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        height: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          overflowY: 'auto'
        }}
      >
        <List sx={{ width: '100%', maxWidth: 800 }}>
          {programs.length > 0 ? (
            programs.map((program, index) => {
              const isLive = isProgramLive(program); // Verifica se o programa está ao vivo
              const highlightText = getHighlightText(isLive); // Obtém o texto de destaque (se estiver ao vivo)

              return (
                <React.Fragment key={program.id}>
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      bgcolor: getHighlightColor(index, isLive), // Define a cor de fundo dependendo se o programa está ao vivo
                      color: isLive ? 'yellow' : 'white'
                    }}
                  >
                    {highlightText && (
                      <Typography
                        variant="caption"
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          bgcolor: 'red',
                          color: 'white',
                          paddingLeft: 0.5,
                          paddingRight: 0.5,
                          borderBottomLeftRadius: 4,
                          animation: `${pulseGlowAnimation} 2s infinite ease-in-out` // Animação de brilho para o texto "NO AR"
                        }}
                      >
                        {highlightText}
                      </Typography>
                    )}
                    <ListItemAvatar>
                      <Avatar alt={program.meta['dj']} src={program.djPhoto} 
                      sx={{ 
                        width: isLive ? { xs: 46, md: 50 } : { xs: 46, md: 50 }, // xs para mobile, 26px md desktop
                        height: isLive ? { xs: 46, md: 50 } : { xs: 46, md: 50 },
                        boxShadow: isLive 
                        ? '0px 0px 10px 2px rgba(255, 255, 255, 0.8)'  // Sombra branca quando ao vivo
                        : '0px 0px 10px 2px rgba(255, 255, 255, 0.8)'
                        }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box sx={{ fontSize:{ xs: '18px', md: '22px' }, color:'#c4fff8', fontWeight:'700', fontFamily: '"Orbitron", sans-serif', marginLeft: { xs: 1, md: 2 }, display: 'inline-flex', alignItems: 'center' }}>
                          {program.title.rendered}
                        </Box>
                      }
                      secondary={
                        <Box sx={{marginLeft: { xs: 0, md: 0.8 }, display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              color: isLive ? '#fff' : '#fff8c4',
                              padding: '2px 8px',
                              borderRadius: 2,
                              fontSize:{ xs: '0.875rem', md: '0.975rem' },
                              marginRight: 0,
                              fontWeight:'700'
                            }}
                          >
                            {`${program.meta['hora-inicio']} - ${program.meta['hora-fim']}`}
                          </Box>
                          <Box
                            sx={{
                              color: 'white',
                              padding: '0px 8px 0px 0px',
                              borderRadius: 2,
                              fontSize: '0.775rem',
                            }}
                          >
                            {`com`}
                          </Box>
                          <Box
                            sx={{
                              color: 'white',
                              borderRadius: 2,
                              fontSize: '0.975rem',
                              fontWeight:'700'
                            }}
                          >
                            {`${program.meta['dj']}`}
                          </Box>
                        </Box>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              );
            })
          ) : (
            <Typography variant="body1" color="textSecondary">
              Nenhum programa disponível para hoje.
            </Typography>
          )}
        </List>
      </Box>
    </Container>
  );
};

export default ProgramList;
