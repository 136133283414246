import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Fade,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Divider
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ProgramacaoModal = ({ open, handleClose }) => {
  // Estado para armazenar a programação e DJs
  const [programacao, setProgramacao] = useState([]);
  const [djs, setDjs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Verifica se o dispositivo é mobile usando breakpoints do tema
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

// Fetch da API quando o modal abre
useEffect(() => {
  if (open) {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [programacaoResponse, djsResponse] = await Promise.all([
          fetch('https://webradiosoundmix.com.br/api/wp-json/wp/v2/programacao?per_page=100'), // Garante que todos os dados de programação sejam trazidos
          fetch('https://webradiosoundmix.com.br/api/wp-json/wp/v2/djs?per_page=100') // Garante que todos os DJs sejam trazidos
        ]);

        const programacaoData = await programacaoResponse.json();
        const djsData = await djsResponse.json();

        setProgramacao(programacaoData);
        setDjs(djsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }
}, [open]);


  // Mapeia DJs por nome para fácil acesso
  const getDjsMap = () => {
    const djsMap = new Map();
    djs.forEach(dj => {
      djsMap.set(dj.meta['nome-do-dj'], dj);
    });
    return djsMap;
  };

  const djsMap = getDjsMap();

  const daysOfWeek = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'];

  // Obtém o dia atual da semana
  const getCurrentDay = () => {
    const now = new Date();
    const dayIndex = now.getDay();
    return daysOfWeek[dayIndex === 0 ? 6 : dayIndex - 1]; // Ajusta índice para o formato da semana
  };

  // Obtém o horário atual no formato HH:MM
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const currentDay = getCurrentDay();
  const currentTime = getCurrentTime();

  // Organiza programas por dia da semana e ordena por hora de início
  const programsByDay = daysOfWeek.reduce((acc, day) => {
    acc[day] = programacao
      .filter(program => program.meta.dia === day)
      .sort((a, b) => a.meta['hora-inicio'].localeCompare(b.meta['hora-inicio']));
    return acc;
  }, {});

  // Verifica se um programa está no ar com base no horário
  const isProgramOnAir = (program) => {
    const startTime = program.meta['hora-inicio'];
    const endTime = program.meta['hora-fim'];
    return currentTime >= startTime && currentTime <= endTime;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'rgba(0, 0, 0, 0.8)'
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            width: isMobile ? '100%' : '80%',
            maxHeight: '80vh',
            overflowY: 'auto',
            bgcolor: '#333',
            color: '#fff',
            borderRadius: 2,
            p: 2,
            position: 'relative',
            zIndex: 1300
          }}
        >
          {/* Botão de fechar o modal, posicionado fixamente no canto superior direito */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 10,
              right: 20,
              color: '#FFD700',
              zIndex: 1400,
              '&:hover': {
                backgroundColor: 'rgba(255, 215, 0, 0.2)',
              }
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Exibe mensagem de carregamento enquanto os dados estão sendo buscados */}
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                bgcolor: '#333',
                color: '#fff',
                borderRadius: 2,
              }}
            >
              <CircularProgress color="inherit" />
              <Typography variant="body2" sx={{ ml: 2 }}>Carregando dados, aguarde...</Typography>
            </Box>
          ) : (
            <>
              {/* Cabeçalhos diferentes para mobile e desktop */}
              {isMobile ? (
                <Typography sx={{ pt: 5 }} variant="h6" component="h2" gutterBottom>
                  GRADE DA PROGRAMAÇÃO AO VIVO
                </Typography>
              ) : (
                <Typography variant="h5" component="h3" gutterBottom>
                  GRADE DA PROGRAMAÇÃO AO VIVO
                </Typography>
              )}

              {/* Conteúdo do modal para mobile */}
              {isMobile ? (
                <Box sx={{ p: 1 }}>
                  {daysOfWeek.map(day => (
                    <Box key={day} sx={{ mb: 3 }}>
                      <Typography variant="h6" gutterBottom sx={{ pl: 2, backgroundColor: day === currentDay ? '#34752e' : '#2E8DFA', color: '#fff' }}>
                        {day}
                      </Typography>
                      {programsByDay[day].map((program, index) => {
                        const dj = djsMap.get(program.meta.dj);
                        const onAir = day === currentDay && isProgramOnAir(program);
                        return (
                          <Box key={index} sx={{ mb: 1, bgcolor: onAir ? '#555' : '#333', p: 1, borderRadius: 1 }}>
                            {/* Nome do Programa */}
                            <Typography sx={{ fontWeight: '700' }} variant="body2">
                              {program.meta['nome-do-programa']}
                            </Typography>

                            {/* Horário do Programa */}
                            <Typography sx={{ fontWeight: '700' }} variant="caption">
                              {program.meta['hora-inicio']} - {program.meta['hora-fim']}
                            </Typography>

                            {/* Informações do DJ */}
                            {dj && (
                              <>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                  <img
                                    src={dj.meta['foto-do-dj']}
                                    alt={dj.meta['nome-do-dj']}
                                    style={{
                                      width: 50,
                                      borderRadius: '50%',
                                      marginRight: 8
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontFamily: '"Orbitron", sans-serif',
                                      letterSpacing: '0.0em',
                                      textTransform: 'uppercase',
                                      fontWeight: '700',
                                      fontSize: '14px'
                                    }}
                                    variant="caption"
                                  >
                                    {dj.meta['nome-do-dj']}
                                  </Typography>
                                </Box>
                                <Divider sx={{ my: 1, bgcolor: '#fff' }} />
                              </>
                            )}

                            {/* Indicação de programa no ar */}
                            {onAir && (
                              <Box
                                sx={{
                                  mt: 1,
                                  bgcolor: '#4CAF50',
                                  color: '#fff',
                                  borderRadius: 1,
                                  padding: '2px 5px',
                                  fontSize: '0.75rem',
                                  textAlign: 'center'
                                }}
                              >
                                NO AR
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  ))}
                </Box>
              ) : (
                // Conteúdo do modal para desktop
                <TableContainer component={Paper} sx={{ bgcolor: '#444', color: '#fff' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {daysOfWeek.map(day => (
                          <TableCell
                            key={day}
                            align="center"
                            sx={{
                              color: day === currentDay ? '#fff' : '#FFD700',
                              fontSize: '20px',
                              bgcolor: day === currentDay ? '#202327' : '#555'
                            }}
                          >
                            {day}
                            {/* Exibe uma seta se for o dia atual */}
                            {day === currentDay && (
                              <KeyboardArrowDownIcon
                                sx={{ fontSize: '30px', color: '#fff' }}
                              />
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {daysOfWeek.map(day => (
                          <TableCell
                            key={day}
                            align="left"
                            sx={{
                              verticalAlign: 'top',
                              backgroundColor: day === currentDay ? '#595959' : '#2A2B2D',
                            }}
                          >
                            {programsByDay[day].map((program, index) => {
                              const dj = djsMap.get(program.meta.dj);
                              const onAir = day === currentDay && isProgramOnAir(program);
                              return (
                                <Box key={index} sx={{ mb: 2, bgcolor: onAir ? '#212529' : '#333', p: 1, borderRadius: 1 }}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color:'#fff', fontWeight: 700 }}
                                  >
                                    {program.meta['nome-do-programa']}
                                  </Typography>
                                  <Typography sx={{color:'#fff'}} variant="caption">
                                    {program.meta['hora-inicio']} - {program.meta['hora-fim']}
                                  </Typography>
                                  {dj && (
                                    <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                                      <img
                                        src={dj.meta['foto-do-dj']}
                                        alt={dj.meta['nome-do-dj']}
                                        style={{
                                          width: 40,
                                          borderRadius: '50%',
                                          marginRight: 8
                                        }}
                                      />
                                      <Typography variant="caption" sx={{ fontFamily: '"Orbitron", sans-serif',color: '#e8ffb5', fontWeight:'700' }}>
                                        {dj.meta['nome-do-dj']}
                                      </Typography>
                                    </Box>
                                  )}
                                  {onAir && (
                                    <Box
  sx={{
    mt: 1,
    bgcolor: '#0c6d1d',
    color: '#fff',
    borderRadius: 1,
    padding: '2px 5px',
    fontSize: '0.75rem',
    textAlign: 'center',
    animation: 'pulse 2s infinite',
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
        opacity: 1,
      },
      '50%': {
        transform: 'scale(1.05)',
        opacity: 0.8,
      },
      '100%': {
        transform: 'scale(1)',
        opacity: 1,
      }
    }
  }}
>
  AGORA NO AR
</Box>
                                  )}
                                </Box>
                              );
                            })}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ProgramacaoModal;
